import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { User } from "../types";

function MenuItemUnderline({ color, width }: { color: string; width: string }) {
  return (
    <div className="flex flex-row justify-center mt-1">
      <div className={`h-[3px] ${width} rounded-full ${color}`} />
    </div>
  );
}

function MenuBar({ user }: { user: User }) {
  const location = useLocation();
  const { hash, pathname, search } = location;

  const altColor = user.experiments.includes("alt-color-061024");
  const planBgColor = altColor
    ? "bg-gradient-to-r from-[#064E3B] via-[#086F54] to-[#0B8D6B]"
    : "bg-gradient-to-r from-[#CD97F9] via-[#EA7CC2] to-[#FF779B]";
  // const chatBgColor = altColor ? "bg-gradient-to-r from-[#003F42] via-[#005354] to-[#006A6A]" : "bg-main-white";
  const chatBgColor = altColor
    ? "bg-main-white backdrop-blur-lg bg-opacity-80"
    : "bg-main-white";
  const chatTextColor = "text-black";
  const chatUnderlineColor = "bg-black";
  const foodBgColor = altColor
    ? "bg-gradient-to-r from-[#9E4330] via-[#B2573A] to-[#C0683F]"
    : "bg-gradient-to-r from-[#006769] to-[#40A578]";

  let bgColor = chatBgColor;
  let textColor = chatTextColor;
  let paddingBottom = "pb-3";
  let underlineColor = chatUnderlineColor;
  if (pathname == "/plan") {
    bgColor = planBgColor;
    textColor = "text-main-white";
    paddingBottom = "pb-3";
    underlineColor = "bg-main-white";
  } else if (pathname == "/profile") {
    bgColor = "bg-gradient-to-r from-[#0A2342] via-[#102E4A] to-[#19376D]";
    textColor = "text-main-white";
    paddingBottom = "pb-3";
    underlineColor = "bg-main-white";
  } else if (pathname == "/food") {
    bgColor = foodBgColor;
    textColor = "text-main-white";
    paddingBottom = "pb-3";
    underlineColor = "bg-main-white";
  }

  return (
    <div
      className={`fixed text-lg top-0 start-0 end-0 z-20 flex flex-row justify-around px-4 pt-5 md:w-1/2 mx-auto font-semibold ${bgColor} ${paddingBottom}`}
    >
      <div
        className={
          `pb-1 text-center ${textColor} ` +
          (pathname == "/chat" ? "" : "text-opacity-70")
        }
      >
        <Link to="/chat">Chat</Link>
        {pathname == "/chat" && (
          <MenuItemUnderline color={underlineColor} width="w-full" />
        )}
      </div>

      <div
        className={
          `pb-1 text-center whitespace-nowrap ${textColor} ` +
          (pathname == "/plan" ? "" : "text-opacity-70")
        }
      >
        <Link to="/plan">Your Program</Link>
        {pathname == "/plan" && (
          <MenuItemUnderline color={underlineColor} width="w-full" />
        )}
      </div>

      <div
        className={
          `pb-1 text-center ${textColor} ` +
          (pathname == "/food" ? "" : "text-opacity-70")
        }
      >
        <Link to="/food">Food</Link>
        {pathname == "/food" && (
          <MenuItemUnderline color={underlineColor} width="w-full" />
        )}
      </div>

      <div
        className={
          `pb-1 text-center ${textColor} ` +
          (pathname == "/profile" ? "" : "text-opacity-70")
        }
      >
        <Link to="/profile">Profile</Link>
        {pathname == "/profile" && (
          <MenuItemUnderline color={underlineColor} width="w-full" />
        )}
      </div>
    </div>
  );
}

export default MenuBar;
