import { User, WeeklyPlan } from "../../types";
import { WeeklyProgram } from "../../components/WeeklyProgram";

function WeeklyPlanMessage({
  user,
  weeklyPlan,
  blur,
}: {
  user: User;
  weeklyPlan: WeeklyPlan;
  blur?: boolean;
}) {
  return (
    <div className={blur ? " blur-sm" : ""}>
      <div className="font-extrabold mb-4 text-center">This week program</div>
      <WeeklyProgram user={user} weeklyPlan={weeklyPlan} showStatus={false} />
    </div>
  );
}

export default WeeklyPlanMessage;
