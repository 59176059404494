import { Link } from "react-router-dom";

function NotificationsUnsubscribedPage() {
  return (
    <div className="flex flex-col justify-around h-full p-4">
      <div>
        <h1 className="font-semibold text-2xl mt-12">Email Unsubscribed</h1>
        <p className="text-gray-600 mt-2">
          You will no longer receive emails with reminders and motivational
          messages.
        </p>
      </div>
      <div className="flex justify-center mt-12">
        <Link
          to="/chat"
          className="text-white bg-button-blue h-[44px] w-[90%] max-w-[300px] rounded-lg flex justify-center items-center"
        >
          Go back to my Coach
        </Link>
      </div>
    </div>
  );
}

export default NotificationsUnsubscribedPage;
