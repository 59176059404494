import { useState } from "react";
import { createCheckoutSession, useGetMe } from "../queries";
import { Spinner, SubscriptionLock15 } from "./Icons";
import { isUserSubscribed } from "../types";
import { Button } from "flowbite-react";
import { IoClose } from "react-icons/io5";

export function PrimaryButton({
  label,
  onClick,
  submitting,
  href,
  variant,
  enabled,
}: {
  label: string;
  onClick: () => void;
  submitting: boolean;
  href?: string;
  variant?: "primary" | "secondary" | "tertiary";
  enabled?: boolean;
}) {
  const isEnabled = enabled === undefined || enabled;

  let className =
    "block w-full text-center font-semibold p-3 rounded-xl cursor-pointer ";
  switch (variant) {
    case "secondary":
      className += isEnabled
        ? "bg-white text-button-blue border border-button-blue"
        : "bg-white text-gray-400 border border-gray-400";
      break;
    case "tertiary":
      className += isEnabled
        ? "bg-white text-gray-500"
        : "bg-white text-gray-300";
      break;
    case "primary":
    default:
      className += isEnabled
        ? "bg-button-blue text-white"
        : "bg-gray-400 text-white";
      break;
  }

  const handleClick = () => {
    if (!isEnabled || submitting) return;
    onClick();
  };

  return (
    <>
      {href && (
        <a href={href} className={className} onClick={onClick}>
          {label}
        </a>
      )}
      {!href && (
        <button type="button" className={className} onClick={handleClick}>
          {submitting && (
            <>
              <Spinner fillColor="#E5E7EB" /> Submitting...
            </>
          )}
          {!submitting && label}
        </button>
      )}
    </>
  );
}

export const YesNoRadioButtons = ({
  initialState,
  onStateChanged,
}: {
  initialState: boolean;
  onStateChanged: (newState: boolean) => void;
}) => {
  const [state, setState] = useState(initialState);

  // Function to update state based on selection
  const handleSelect = (newState: boolean) => {
    if (state === newState) return;
    setState(newState);
    onStateChanged(newState);
  };

  const classNameSelected =
    "border-1 min-w-28 max-w-44 basis-2/5 py-3 rounded-xl border border-button-blue text-sm font-medium text-center bg-button-blue text-white";
  const classNameNotSelected =
    "border-1 min-w-28 max-w-44 basis-2/5 py-3 rounded-xl border border-button-blue text-sm font-medium text-center bg-button-blue-light text-button-blue";

  return (
    <div className="flex flex-row space-x-2">
      <button
        type="button"
        onClick={() => handleSelect(true)}
        className={state === true ? classNameSelected : classNameNotSelected}
      >
        Yes
      </button>

      <button
        type="button"
        onClick={() => handleSelect(false)}
        className={state === false ? classNameSelected : classNameNotSelected}
      >
        No
      </button>
    </div>
  );
};

export function SubscribeButton() {
  // TODO: add onClick handler to show the paywall modal
  return (
    <div className="flex flex-col gap-2 font-semibold">
      <div className="flex">
        <div className="text-4xl">🔐</div>
        <span className="">
          <div className="text-sm text-gray-500 font-semibold">
            14-Day Free. Cancel anytime.
          </div>
          <div className="text-sm text-gray-500 font-semibold">
            Then less than a cup of coffee per month.
          </div>
        </span>
      </div>
      <Button className="mt-1 bg-[#4989E8] text-sm font-semibold">
        Start free trial
      </Button>
    </div>
  );
}

export function PaywallInlineIcon({
  color,
  orText,
  prefix,
  suffix,
  disabled,
}: {
  color: string;
  orText?: string;
  prefix?: string;
  suffix?: string;
  disabled?: boolean;
}) {
  const me = useGetMe();
  if (disabled || me.isLoading || !me.data || isUserSubscribed(me.data)) {
    return <span>{orText ? orText : ""}</span>;
  }

  return (
    <>
      {prefix && <span>{prefix}</span>}
      <span>
        <SubscriptionLock15 color={color} />
      </span>
      {suffix && <span>{suffix}</span>}
    </>
  );
}

export function ModalCloseButton({ onClick }: { onClick: () => void }) {
  return (
    <button type="button" onClick={onClick} className="p-4">
      <div className="flex justify-center items-center w-7 h-7 rounded-full bg-[#7F7F7F] bg-opacity-25 text-[#8D8D8D]">
        <IoClose size={18} />
      </div>
    </button>
  );
}
