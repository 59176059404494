import { useState } from "react";
import { Modal } from "flowbite-react";
import { User } from "../types";
import {
  weekStreakRequiredTaskCount,
  weekDoneTaskCount,
  isThisWeekStreakRequirementMet,
  streakProgressTitleAndText,
} from "../week-progress-util";
import { Streak41 } from "./Icons";
import EmojiRain from "./EmojiRain";

function WeekStreakDonutProgressBar({ user }: { user: User }) {
  const requiredCount = weekStreakRequiredTaskCount(user);
  const doneCount = weekDoneTaskCount(user);
  const completionAngle =
    doneCount >= requiredCount
      ? 360
      : Math.max(Math.round((doneCount / requiredCount) * 360), 10);

  const segmentStyle = {
    background: `conic-gradient(
      transparent 0deg,
      transparent ${completionAngle}deg,
      #ededed ${completionAngle}deg,
      #ededed 360deg
    )`,
  };

  const streak = user.previousWeeksStreak + 1;
  const isStreakCompleted = isThisWeekStreakRequirementMet(user);

  return (
    <div className="w-[70%] max-w-[250px] aspect-square rounded-full bg-gradient-to-b from-[#B449B6] to-[#DE50B6] relative">
      <div className="w-full h-full rounded-full p-[34px]" style={segmentStyle}>
        <div className="flex flex-col items-center justify-center w-full h-full rounded-full bg-white">
          {!isStreakCompleted && (
            <div className="text-lg font-semibold text-gray-600 leading-none">
              week
            </div>
          )}
          <div className="text-[80px] font-bold text-center leading-none">
            {streak}
          </div>
          {isStreakCompleted && (
            <div className="text-lg font-semibold text-gray-600 leading-none">
              weeks
            </div>
          )}
        </div>
      </div>
      <div
        className={
          "absolute left-1/2 top-[17px] transform -translate-x-1/2 -translate-y-1/2 rounded-full w-[70px] h-[70px] flex items-center justify-center shadow-[0_4px_50px_0px_rgba(0,0,0,0.25)] " +
          (isStreakCompleted
            ? "bg-gradient-to-b from-[#FFC634] to-[#FF910F]"
            : "bg-white")
        }
      >
        <Streak41
          color1={isStreakCompleted ? "white" : "#FDD547"}
          color2={isStreakCompleted ? "white" : "#FF910F"}
        />
      </div>
    </div>
  );
}

export function WeekStreakModal({
  show,
  user,
  isRewardScreen,
  onClose,
}: {
  show: boolean;
  user: User;
  isRewardScreen: boolean;
  onClose: () => void;
}) {
  const [showEmojiRain, setShowEmojiRain] = useState(isRewardScreen);
  const titleAndText = streakProgressTitleAndText(user, isRewardScreen);
  const title = titleAndText[0];
  const text = titleAndText[1];

  return (
    <Modal
      show={show}
      onClose={onClose}
      dismissible={true}
      position="bottom-center"
    >
      <Modal.Header>
        <span className="font-semibold">My Streak</span>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col items-center">
          <WeekStreakDonutProgressBar user={user} />
          <div className="text-center mt-10">
            <div className="font-semibold text-[28px]">{title}</div>
            <div className="text-gray-600 mt-2">{text}</div>
          </div>
          <button
            type="button"
            className="flex items-center justify-center mt-12 mb-6 h-[44px] w-[80%] max-w-[350px] rounded-lg bg-button-blue text-white font-semibold text-sm"
            onClick={onClose}
          >
            To my program
          </button>
          {showEmojiRain && (
            <EmojiRain
              emojiList={["🔥", "✨", "⚡"]}
              totalEmojiCount={40}
              onEnd={() => setShowEmojiRain(false)}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
