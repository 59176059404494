import { useEffect, useState, ReactNode } from "react";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Modal } from "flowbite-react";
import { Fire, Streak15 } from "../../components/Icons";
import AppLayout from "../../AppLayout";
import {
  TextAreaField,
  TextareaField,
  SelectNumberField,
  SelectWeightKgField,
  SelectWeightLbsField,
  SelectTextField,
  PhoneInputField,
} from "../../components/Form";
import { PrimaryButton } from "../../components/Buttons";
import AddBuddyModal from "../../components/AddBuddyModal";
import ProfileName from "../../components/ProfileName";
import { NotificationsSection } from "./NotificationsModal";
import { useGetMe, useEditMe } from "../../queries";
import { config } from "../../Constants";
import { User, Gender } from "../../types";
import { Crown } from "../../components/Icons";
import { ProgressModal } from "../../components/ProgressModal";

import LibrarySection from "./LibrarySection";
import { currentWeekStreak } from "../../week-progress-util";

function ProfileHeader({ user }: { user: User }) {
  const [showProgressModal, setShowProgressModal] = useState(false);

  const streak = currentWeekStreak(user);

  return (
    // The white border is needed to prevent the background image from leaking
    // through the cracks.
    <div className="flex flex-col gap-0 text-white border-b border-main-white bg-gradient-to-r from-[#0A2342] via-[#102E4A] to-[#19376D]">
      <ProgressModal
        user={user}
        show={showProgressModal}
        onClose={() => setShowProgressModal(false)}
      />
      <div className="flex flex-row justify-between items-center mt-20 px-4">
        <ProfileName user={user} />
      </div>

      <div className="flex flex-row px-4 mt-1 mb-5 justify-between text-white font-semibold">
        <button
          type="button"
          onClick={() => setShowProgressModal(true)}
          className="bg-white bg-opacity-20 rounded-full pl-3 pr-4 py-2"
        >
          <div className="flex flex-row items-center gap-1">
            <Fire />
            <div>{user.fires}</div>
          </div>
        </button>
        <button
          type="button"
          className="bg-white bg-opacity-20 rounded-full px-3 py-2"
          onClick={() => setShowProgressModal(true)}
        >
          <div className="flex flex-row items-center gap-1">
            <Crown />
            <div>Level {user.level}</div>
          </div>
        </button>
        <button
          type="button"
          className="bg-main-white bg-opacity-20 rounded-full px-3 py-2"
          onClick={() => setShowProgressModal(true)}
        >
          <div className="flex flex-row items-center gap-1">
            <div>
              <Streak15 /> {streak} {streak == 1 ? "week" : "weeks"} streak
            </div>
          </div>
        </button>
      </div>
      <div className="w-full h-4 bg-main-white rounded-t-lg"> </div>
    </div>
  );
}

function ProfileSection({
  title,
  onEdit,
  children,
}: {
  title: string;
  onEdit?: () => void;
  children: ReactNode;
}) {
  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <h4 className="text-lg font-semibold">{title}</h4>
        {onEdit && (
          <button
            type="button"
            onClick={onEdit}
            className="text-blue-link cursor-pointer font-[500]"
          >
            Edit
          </button>
        )}
      </div>
      {children}
    </div>
  );
}

function EditGoalModal({
  open,
  setOpen,
  user,
}: {
  open: boolean;
  setOpen: (b: boolean) => void;
  user: User;
}) {
  const [goal, setGoal] = useState<string>(user.goal);
  const [targetWeight, setTargetWeight] = useState<number>(
    user.targetWeightKg || user.targetWeightLbs || 0,
  );

  const useEditMeMutation = useEditMe();

  const handleSubmit = () => {
    let nu = { ...user };
    nu.goal = goal;
    if (user.targetWeightKg) nu.targetWeightKg = targetWeight;
    else if (user.targetWeightLbs) nu.targetWeightLbs = targetWeight;
    useEditMeMutation.mutate(nu);
    setOpen(false);
  };

  return (
    <Modal dismissible={true} show={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <span className="font-semibold">Goals</span>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <TextareaField
            id="goal"
            label="Your goal"
            value={goal}
            setValue={setGoal}
            placeholder="E.g. Lose weight"
            rows={2}
          />

          {user.targetWeightKg && (
            <SelectWeightKgField
              id="target_weight"
              label="What’s your target weight?"
              value={targetWeight}
              setValue={setTargetWeight}
            />
          )}
          {user.targetWeightLbs && (
            <SelectWeightLbsField
              id="target_weight"
              label="What’s your target weight?"
              value={targetWeight}
              setValue={setTargetWeight}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          onClick={handleSubmit}
          label={"Submit"}
          submitting={false}
        />
      </Modal.Footer>
    </Modal>
  );
}
function GoalSection({ user }: { user: User }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <ProfileSection title="Goal" onEdit={() => setOpenModal(true)}>
      {user.goal && (
        <>
          <div className="">{user.goal}</div>
          <div>
            Target weight: {user.targetWeightKg && user.targetWeightKg + " kg"}
            {user.targetWeightLbs && user.targetWeightLbs + " lbs"}
          </div>
        </>
      )}
      {!user.goal && <div className="italic">Not yet defined</div>}
      <EditGoalModal open={openModal} setOpen={setOpenModal} user={user} />
    </ProfileSection>
  );
}

function monthsToLoseWeightKg(current: number, target: number): number {
  // Recommendations are 0.5-1 kg (1-2 pounds) per week, so we take 0.75 per
  // week, x4 per month is 3kg per month, we round it up to 3.5kg to account
  // for the remaining days and make it optimistic (Another source also says
  // 4kg per month is safe).
  return Math.ceil((current - target) / 3.5);
}
function monthsToLoseWeightLbs(current: number, target: number): number {
  return Math.ceil((current - target) / 7.5);
}
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function StatusSection({ user }: { user: User }) {
  let monthsToTarget =
    user.currentWeightKg && user.targetWeightKg
      ? monthsToLoseWeightKg(user.currentWeightKg, user.targetWeightKg)
      : user.currentWeightLbs && user.targetWeightLbs
        ? monthsToLoseWeightLbs(user.currentWeightLbs, user.targetWeightLbs)
        : 0;
  var today = new Date();
  var targetDate = new Date();
  targetDate.setMonth(targetDate.getMonth() + monthsToTarget);

  return (
    <div>
      <h4 className="text-lg font-semibold">Status</h4>
      {(user.currentWeightKg || user.currentWeightLbs) && (
        <>
          <div className="">
            Current weight:{" "}
            {user.currentWeightKg && user.currentWeightKg + " kg"}
            {user.currentWeightLbs && user.currentWeightLbs + " lbs"}
          </div>
          {user.weeklyPlan && monthsToTarget > 0 && monthsToTarget < 12 && (
            <div className="mt-6">
              You are on track! Based on your current progress and best
              practices, you’ll reach your target weight of{" "}
              <strong>
                {user.targetWeightKg && user.targetWeightKg + " kg"}
                {user.targetWeightLbs && user.targetWeightLbs + " lbs"} in{" "}
                {MONTHS[targetDate.getMonth()]}!
              </strong>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function AccountabilityBuddiesSection({ user }: { user: User }) {
  const [selectedBuddy, setSelectedBuddy] = useState<number | undefined>(
    undefined,
  );
  const [openModal, setOpenModal] = useState<boolean>(false);

  const buddyColors = [
    "bg-button-blue",
    "bg-yellow-500",
    "bg-red-500",
    "bg-green-500",
    "bg-purple-500",
    "bg-orange-500",
  ];

  return (
    <div>
      {(openModal || selectedBuddy !== undefined) && (
        <AddBuddyModal
          open={openModal || selectedBuddy !== undefined}
          setOpen={() => {
            setOpenModal(false);
            setSelectedBuddy(undefined);
          }}
          user={user}
          buddy={
            selectedBuddy === undefined
              ? undefined
              : user.buddies[selectedBuddy]
          }
          buddyIndex={selectedBuddy === undefined ? 0 : selectedBuddy}
        />
      )}
      <h4 className="text-lg font-semibold">Accountability buddies</h4>
      <div className="text-sm mt-2 text-gray-700">
        You can add buddies to share your progress at the end of every week. No
        sign-up required for them.
      </div>

      <div className="flex flex-col gap-4 my-4">
        {user.buddies.map((buddy, i) => (
          <div
            key={buddy.email}
            className="flex flex-row justify-between items-center"
          >
            <button
              type="button"
              className="flex flex-row gap-2 grow justify-start items-center"
              onClick={() => setSelectedBuddy(i)}
            >
              <div
                className={
                  "basis-12 h-12 rounded-full text-white text-2xl font-medium flex justify-center items-center " +
                  buddyColors[i % buddyColors.length]
                }
              >
                {buddy.name[0].toUpperCase()}
              </div>
              <div className="">{buddy.name}</div>
            </button>
            <div>&#x3009;</div>
          </div>
        ))}
      </div>

      <div className="mx-4 mt-4">
        <PrimaryButton
          onClick={() => setOpenModal(true)}
          label={"Add buddy"}
          submitting={false}
          variant={"secondary"}
        />
      </div>
    </div>
  );
}

function EditSuccessBarrierModal({
  open,
  setOpen,
  user,
}: {
  open: boolean;
  setOpen: (b: boolean) => void;
  user: User;
}) {
  const [successBarrier, setSuccessBarrier] = useState<string>(
    user.successBarrier,
  );

  const useEditMeMutation = useEditMe();

  const handleSubmit = () => {
    useEditMeMutation.mutate({
      ...user,
      successBarrier: successBarrier,
    });
    setOpen(false);
  };

  return (
    <Modal dismissible show={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <span className="font-semibold">What might prevent your success</span>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <TextAreaField
            id="goal"
            value={successBarrier}
            setValue={setSuccessBarrier}
            placeholder="Enter a text"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          onClick={handleSubmit}
          label={"Submit"}
          submitting={false}
        />
      </Modal.Footer>
    </Modal>
  );
}
function SuccessBarrierSection({ user }: { user: User }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <ProfileSection
      title="What might prevent your success"
      onEdit={() => setOpenModal(true)}
    >
      <div className="text-sm mt-2 text-gray-700">
        {user.successBarrier && <div>{user.successBarrier}</div>}
        {!user.successBarrier && (
          <div className="text-slate-400 italic">empty</div>
        )}
      </div>
      <EditSuccessBarrierModal
        open={openModal}
        setOpen={setOpenModal}
        user={user}
      />
    </ProfileSection>
  );
}

function EditThingsToKnowModal({
  open,
  setOpen,
  user,
}: {
  open: boolean;
  setOpen: (b: boolean) => void;
  user: User;
}) {
  const [otherComment, setOtherComment] = useState<string>(user.otherComment);

  const useEditMeMutation = useEditMe();

  const handleSubmit = () => {
    useEditMeMutation.mutate({
      ...user,
      otherComment: otherComment,
    });
    setOpen(false);
  };

  return (
    <Modal dismissible show={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <span className="font-semibold">Things to know about you</span>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <TextareaField
            id="goal"
            value={otherComment}
            setValue={setOtherComment}
            placeholder="Injuries to know about, schedule constraints, or something particular you'd like to incorporate in your plans, etc... Be as clear as possible"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          onClick={handleSubmit}
          label={"Submit"}
          submitting={false}
        />
      </Modal.Footer>
    </Modal>
  );
}
function ThingsAboutYouSection({ user }: { user: User }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <ProfileSection
      title="Things to know about you"
      onEdit={() => setOpenModal(true)}
    >
      <div className="text-sm mt-2 text-gray-700">
        {user.otherComment
          .split("\n")
          .map((line: string, i: number) => line && <div key={i}>{line}</div>)}
      </div>
      <EditThingsToKnowModal
        open={openModal}
        setOpen={setOpenModal}
        user={user}
      />
    </ProfileSection>
  );
}

function EditPreferencesModal({
  open,
  setOpen,
  user,
}: {
  open: boolean;
  setOpen: (b: boolean) => void;
  user: User;
}) {
  const [name, setName] = useState<string>(user.name);
  const [gender, setGender] = useState<Gender>(user.gender);
  const [age, setAge] = useState<number>(user.age);
  const [sessionsPerWeek, setSessionsPerWeek] = useState<number>(
    user.sessionsPerWeek,
  );
  const [phone, setPhone] = useState<string>(user.phone || "");

  const useEditMeMutation = useEditMe();

  const handleSubmit = () => {
    useEditMeMutation.mutate({
      ...user,
      name: name,
      gender: gender,
      age: age,
      sessionsPerWeek: sessionsPerWeek,
      phone: phone,
    });
    setOpen(false);
  };

  return (
    <Modal dismissible show={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <span className="font-semibold">Preferences</span>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4">
          <TextAreaField
            id="name"
            label="Name"
            value={name}
            setValue={setName}
            placeholder="Enter your name"
          />
          <SelectTextField
            id="age"
            label="Gender"
            value={gender}
            setValue={(g: any) => setGender(g)}
            options={[
              { label: "Male", value: "male" },
              { label: "Female", value: "female" },
              { label: "Other", value: "other" },
            ]}
          />
          <SelectNumberField
            id="age"
            label="Age"
            value={age}
            setValue={setAge}
            min={16}
            max={99}
          />
          <SelectNumberField
            id="sessions"
            label="Number of times I can realistically exercise per week"
            value={sessionsPerWeek}
            setValue={setSessionsPerWeek}
            min={1}
            max={7}
          />
          {/* <PhoneInputField
            id="phone"
            label="Your phone number"
            value={phone}
            setValue={setPhone}
          /> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          onClick={handleSubmit}
          label={"Submit"}
          submitting={false}
        />
      </Modal.Footer>
    </Modal>
  );
}
function PreferencesSection({ user }: { user: User }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <ProfileSection title="Preferences" onEdit={() => setOpenModal(true)}>
      <div className="flex flex-col text-sm mt-2 text-gray-700">
        <div>Name: {user.name}</div>
        <div>
          Sex: <span className="capitalize">{user.gender}</span>
        </div>
        <div>Age: {user.age}</div>
        <div>Can exercise {user.sessionsPerWeek} times per week</div>
        {/* {user.phone && <div>Phone number: {user.phone}</div>} */}
        {user.email && <div>Email: {user.email}</div>}
      </div>
      <EditPreferencesModal
        open={openModal}
        setOpen={setOpenModal}
        user={user}
      />
    </ProfileSection>
  );
}

function ProfileComponent({ user }: { user: User }) {
  const queryClient = useQueryClient();
  const [userTime, setUserTime] = useState<undefined | string>(undefined);

  useEffect(() => {
    fetch(`${config.url.API_URL}/current-time`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => setUserTime(res.time));
  });

  // True when the initial profile is setup (after the intake form).
  const profileSetUp: boolean =
    user.name != null &&
    (user.currentWeightKg != null || user.currentWeightLbs != null);
  // True once the onboarding is completed, that is once the first weekly plan is generated.
  const onboardingCompleted: boolean = user.weeklyPlan != null;
  const hasSubscription: boolean = user.stripeCustomerId != null;

  return (
    <div
      className="bg-main-white flex-grow overflow-y-auto pb-20 text-gray-800"
      id="profile_page_content"
    >
      {!profileSetUp && (
        <div className="px-4 pt-4">
          Your profile is not ready yet.{" "}
          <Link to="/chat" className="text-blue-link hover:text-blue-600">
            Talk with your coach
          </Link>{" "}
          to set it up.
        </div>
      )}
      {profileSetUp && (
        <>
          <ProfileHeader user={user} />
          <div className="flex flex-col gap-10 px-4 pt-4">
            <GoalSection user={user} />
            <StatusSection user={user} />
            <AccountabilityBuddiesSection user={user} />
            <SuccessBarrierSection user={user} />
            <ThingsAboutYouSection user={user} />
            <PreferencesSection user={user} />
            {hasSubscription && <LibrarySection user={user} />}
          </div>
        </>
      )}

      <div className="px-4">
        <div className="my-4 flex flex-col gap-2">
          <h4 className="mt-4 text-lg font-semibold">Account</h4>
          <a
            className="text-blue-link hover:text-blue-600"
            href={`${config.url.API_URL}/logout`}
          >
            Logout
          </a>
          {hasSubscription && (
            <a
              className="text-blue-link hover:text-blue-600"
              href="#"
              onClick={() =>
                fetch(`${config.url.API_URL}/stripe-customer-portal`, {
                  method: "POST",
                  credentials: "include",
                })
                  .then((res) => res.json())
                  .then((res) => {
                    window.location.href = res.url;
                  })
              }
            >
              Manage Subscription
            </a>
          )}
          <NotificationsSection user={user} />
          <a
            className="text-blue-link hover:text-blue-600"
            href="mailto:support@personalcoach.app"
          >
            Email Support
          </a>
          <Link to="/delete-me" className="text-red-400 hover:text-red-600">
            Delete Account
          </Link>
        </div>
        {config.GOD_MODE && (
          <div className="mb-4 flex flex-col gap-2">
            {userTime && <div>Current time: {userTime}</div>}
            <a
              className="text-blue-link hover:text-blue-600"
              href="#"
              onClick={() => {
                fetch(`${config.url.API_URL}/next-morning`, {
                  method: "POST",
                  credentials: "include",
                })
                  .then((res) => res.json())
                  .then((res) => {
                    setUserTime(res.time);
                    queryClient.invalidateQueries({ queryKey: ["messages"] });
                  });
              }}
            >
              Next Morning
            </a>
            <a
              className="text-blue-link hover:text-blue-600"
              href="#"
              onClick={() => {
                fetch(`${config.url.API_URL}/next-week`, {
                  method: "POST",
                  credentials: "include",
                })
                  .then((res) => res.json())
                  .then((res) => {
                    setUserTime(res.time);
                    queryClient.invalidateQueries({ queryKey: ["messages"] });
                  });
              }}
            >
              Next Weekly Feedback Time
            </a>
            <a
              className="text-blue-link hover:text-blue-600"
              href="#"
              onClick={() => {
                fetch(`${config.url.API_URL}/next-feedback-timeout`, {
                  method: "POST",
                  credentials: "include",
                })
                  .then((res) => res.json())
                  .then((res) => {
                    setUserTime(res.time);
                    queryClient.invalidateQueries({ queryKey: ["messages"] });
                  });
              }}
            >
              Feedback Timeout
            </a>
            <a
              className="text-blue-link hover:text-blue-600"
              href="#"
              onClick={() => {
                fetch(`${config.url.API_URL}/start-subscription`, {
                  method: "POST",
                  credentials: "include",
                })
                  .then((data) => {
                    queryClient.invalidateQueries({ queryKey: ["messages"] });
                  })
                  .catch((error) => console.error("Error:", error));
              }}
            >
              Start Subscription
            </a>
            <a
              className="text-blue-link hover:text-blue-600"
              href="#"
              onClick={() => {
                fetch(`${config.url.API_URL}/reset-subscription`, {
                  method: "POST",
                  credentials: "include",
                });
              }}
            >
              Reset Subscription
            </a>
            <a
              className="text-blue-link hover:text-blue-600"
              href={`${config.url.API_URL}/reset`}
            >
              Reset user
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

function ProfilePage() {
  const { data: user, isLoading, isError } = useGetMe();

  useEffect(() => {
    const pageContent = document.getElementById("profile_page_content");
    if (!pageContent) return;

    let savedScroll = window.sessionStorage.getItem("profile_scroll");

    let scroll = 0;
    if (savedScroll) {
      scroll = parseInt(savedScroll);
    }
    pageContent.scrollTop = scroll;
  }, []);

  useEffect(() => {
    const pageContent = document.getElementById("profile_page_content");
    if (!pageContent) return;

    const onScroll = () => {
      window.sessionStorage.setItem(
        "profile_scroll",
        pageContent.scrollTop.toString(),
      );
    };
    // clean up code
    pageContent.removeEventListener("scroll", onScroll);
    pageContent.addEventListener("scroll", onScroll, { passive: true });
    return () => pageContent.removeEventListener("scroll", onScroll);
  }, []);

  if (isLoading) return <></>;

  if (!user) {
    window.location.href = `${config.url.API_URL}/login?page=/profile`;
    return <></>;
  }

  return (
    <AppLayout user={user}>
      <ProfileComponent user={user} />
    </AppLayout>
  );
}

export default ProfilePage;
