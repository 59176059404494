import React, { useEffect, useState, ReactNode } from "react";
import { Modal } from "flowbite-react";
import { NotificationChannel, User } from "../../types";
import { useEditMe } from "../../queries";
import { PrimaryButton } from "../../components/Buttons";

function NotificationsModal({
  user,
  open,
  setOpen,
}: {
  user: User;
  open: boolean;
  setOpen: (b: boolean) => void;
}) {
  const [notificationChannel, setNotificationChannel] = useState<
    NotificationChannel | null | undefined
  >(user.notificationChannel);

  const useEditMeMutation = useEditMe();

  const handleSubmit = () => {
    if (user.notificationChannel !== notificationChannel) {
      useEditMeMutation.mutate({
        ...user,
        notificationChannel: notificationChannel,
      });
    }
    setOpen(false);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationChannel(event.target.value as NotificationChannel);
  };

  return (
    <Modal dismissible={true} show={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <span className="font-semibold">Manage Notifications</span>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col">
          <label className="inline-flex items-center mt-3">
            <input
              type="radio"
              value="email"
              name="notification"
              className="form-radio h-5 w-5 text-blue-600"
              checked={!notificationChannel || notificationChannel === "email"}
              onChange={handleOptionChange}
            />
            <span className="ml-2 text-gray-700">Email only</span>
          </label>
          {/* {(user.phone || notificationChannel === "sms") && (
            <label className="inline-flex items-center mt-3">
              <input
                type="radio"
                value="sms"
                name="notification"
                className="form-radio h-5 w-5 text-blue-600"
                checked={notificationChannel === "sms"}
                onChange={handleOptionChange}
              />
              <span className="ml-2 text-gray-700">SMS only</span>
            </label>
          )} */}
          <label className="inline-flex items-center mt-3">
            <input
              type="radio"
              value="none"
              name="notification"
              className="form-radio h-5 w-5 text-blue-600"
              checked={notificationChannel === "none"}
              onChange={handleOptionChange}
            />
            <span className="ml-2 text-gray-700">
              No notifications - reminders and motivational messages won't be
              sent anymore
            </span>
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton
          onClick={handleSubmit}
          label={"Submit"}
          submitting={false}
        />
      </Modal.Footer>
    </Modal>
  );
}

function NotificationsSection({ user }: { user: User }) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      {(user.email || user.phone) && (
        <>
          <NotificationsModal
            user={user}
            open={openModal}
            setOpen={setOpenModal}
          />
          <a
            className="text-blue-link hover:text-blue-600"
            href="#"
            onClick={() => setOpenModal(true)}
          >
            Manage Notifications
          </a>
        </>
      )}
    </>
  );
}

export { NotificationsSection };
