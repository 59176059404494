import { useState } from "react";
import { createCheckoutSession, useGetMe } from "../queries";
import { ModalCloseButton } from "./Buttons";
import { isUserSubscribed } from "../types";
import { usePostHog } from "posthog-js/react";
import { Modal } from "flowbite-react";
import { HiMiniCheckCircle } from "react-icons/hi2";
import { FaCheck } from "react-icons/fa";
import type { CustomFlowbiteTheme } from "flowbite-react";

const customTheme: CustomFlowbiteTheme["modal"] = {
  header: {
    title: "grow text-center text-xl font-medium text-gray-900 dark:text-white",
  },
  body: {
    base: "flex-1 overflow-auto p-0",
  },
};

function PaywallModalContent({ onClose }: { onClose: () => void }) {
  const [annual, setAnnual] = useState(false);

  const monthlyPlanButtonStyle = annual
    ? "bg-white border border-[#575757]"
    : "bg-gradient-to-r from-[#C99BFE] to-[#FF769B]";
  const annualPlanButtonStyle = annual
    ? "bg-gradient-to-r from-[#C99BFE] to-[#FF769B]"
    : "bg-white border border-[#575757]";
  const monthlyPlanTextColor = annual ? "text-gray-800" : "text-white";
  const annualPlanTextColor = annual ? "text-white" : "text-gray-800";

  return (
    <div className="flex flex-col mt-2 mb-8">
      <div className="flex justify-end">
        <ModalCloseButton onClick={onClose} />
      </div>
      <h1 className="text-[28px] font-semibold text-center mb-4 px-8">
        <p>Subscribe &</p>
        <p>Get Full Access</p>
      </h1>
      <div className="flex justify-center">
        <div className="flex flex-col mt-6 mx-6 gap-3 flex-1 max-w-[340px]">
          <div className="flex items-center gap-2">
            <HiMiniCheckCircle size={22} className="text-[#4989E8]" />
            <div className="text-sm font-semibold text-gray-600">
              Adaptive program to change your habits
            </div>
          </div>
          <div className="flex items-center gap-2">
            <HiMiniCheckCircle size={22} className="text-[#4989E8]" />
            <div className="text-sm font-semibold text-gray-600">
              Unlimited chats with your coach
            </div>
          </div>
          <div className="flex items-center gap-2">
            <HiMiniCheckCircle size={22} className="text-[#4989E8]" />
            <div className="text-sm font-semibold text-gray-600">
              Nutritional tips tied to your needs
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-16 font-semibold text-sm text-gray-600">
        Choose your payment:
      </div>

      <div className="flex justify-center mt-5">
        <button
          type="button"
          className={`flex mx-5 pl-5 pr-2 flex-1 max-w-[340px] h-[64px] rounded-2xl transition-all ${monthlyPlanButtonStyle}`}
          onClick={() => setAnnual(false)}
        >
          <div className="flex flex-col flex-1 text-left">
            <p className="mt-2">
              <span className={`font-semibold ${monthlyPlanTextColor}`}>
                14 Day Free&nbsp;
              </span>
              <span
                className={`text-sm text-opacity-75 font-semibold ${monthlyPlanTextColor}`}
              >
                then&nbsp;
              </span>
              <span className={`font-bold ${monthlyPlanTextColor}`}>$4</span>
              <span
                className={`text-sm text-opacity-75 font-semibold ${monthlyPlanTextColor}`}
              >
                /month
              </span>
            </p>
            <p
              className={`text-sm text-opacity-75 font-semibold ${monthlyPlanTextColor}`}
            >
              billed monthly
            </p>
          </div>
          {!annual && (
            <div className="mt-2 flex items-center justify-center rounded-full bg-[#004591] h-[22px] w-[22px]">
              <FaCheck size={12} className="text-white" />
            </div>
          )}
          {annual && (
            <div className="mt-2 rounded-full bg-white h-[22px] w-[22px] border border-[#575757]"></div>
          )}
        </button>
      </div>

      <div className="flex justify-center mt-5">
        <button
          type="button"
          className={`flex mx-5 pl-5 pr-2 flex-1 max-w-[340px] h-[64px] rounded-2xl transition-all ${annualPlanButtonStyle}`}
          onClick={() => setAnnual(true)}
        >
          <div className="flex flex-col flex-1 text-left">
            <p className="mt-2">
              <span className={`font-semibold ${annualPlanTextColor}`}>
                14 Day Free&nbsp;
              </span>
              <span
                className={`text-sm text-opacity-75 font-semibold ${annualPlanTextColor}`}
              >
                then&nbsp;
              </span>
              <span
                className={`text-sm text-opacity-75 font-semibold line-through ${annualPlanTextColor}`}
              >
                $3
              </span>
              <span className={`font-bold ${annualPlanTextColor}`}>
                &nbsp;$2
              </span>
              <span
                className={`text-sm text-opacity-75 font-semibold ${annualPlanTextColor}`}
              >
                /month
              </span>
            </p>
            <p
              className={`text-sm text-opacity-75 font-semibold ${annualPlanTextColor}`}
            >
              billed annually
            </p>
          </div>
          {annual && (
            <div className="mt-2 flex items-center justify-center rounded-full bg-[#004591] h-[22px] w-[22px]">
              <FaCheck size={12} className="text-white" />
            </div>
          )}
          {!annual && (
            <div className="mt-2 rounded-full bg-white h-[22px] w-[22px] border border-[#575757]"></div>
          )}
        </button>
      </div>

      <div className="flex justify-center mt-12">
        <button
          type="button"
          className="mx-5 flex-1 max-w-[340px] h-[44px] rounded-lg text-white font-semibold bg-[#007AFF]"
          onClick={() => createCheckoutSession(annual)}
        >
          Subscribe with free trial
        </button>
      </div>
      <div className="flex justify-center text-xs font-semibold text-[#7B7E87] mt-2">
        Cancel anytime. No payments before the end of the trial.
      </div>
    </div>
  );
}

function PaywallModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      dismissible={true}
      size="4xl"
      theme={customTheme}
    >
      <Modal.Body>
        <PaywallModalContent onClose={onClose} />
      </Modal.Body>
    </Modal>
  );
}

export function PaywallButton({
  enablePaywall,
  onClick,
  children,
}: {
  enablePaywall: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) {
  const me = useGetMe();
  const [showModal, setShowModal] = useState(false);
  const posthog = usePostHog();

  if (me.isLoading || !me.data) {
    return <></>;
  }

  const hasSubscription = isUserSubscribed(me.data);

  const onClickWithPaywall = () => {
    if (hasSubscription || !enablePaywall) {
      onClick();
    } else {
      posthog.capture("user_clicked_paywall_button", {});
      setShowModal(true);
    }
  };

  return (
    <>
      <PaywallModal show={showModal} onClose={() => setShowModal(false)} />
      <button type="button" onClick={onClickWithPaywall}>
        {children}
      </button>
    </>
  );
}
