import React, { Fragment } from "react";

import FormMessage from "./FormMessage";
import CallToActionMessage from "./CallToActionMessage";
import WeeklyPlanMessage from "./WeeklyPlanMessage";
import { User, Message } from "../../types";
import DailyPlanMessage from "./DailyPlanMessage";

function IsTyping({ altColor }: { altColor: boolean }) {
  const coachMessageColor = altColor
    ? "bg-gradient-to-r from-[#3d5d65] to-[#6b8e96]"
    : "bg-coach";
  return (
    <div className={`dots py-2 rounded-2xl ${coachMessageColor}`}>
      <div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
}

function formatMessage({
  message,
  user,
  blur,
}: {
  message: Message;
  user: User;
  blur: boolean;
}) {
  if (message.messageType === "form") {
    const formContent = JSON.parse(message.content || "{}");
    return (
      <FormMessage
        user={user}
        formContent={formContent}
        conversationId={message.conversationId}
        messageId={message.id}
      />
    );
  } else if (message.messageType === "plan") {
    const planData: any = JSON.parse(message.content || "{}");
    return <WeeklyPlanMessage user={user} weeklyPlan={planData} blur={blur} />;
  } else if (message.messageType === "call_to_action") {
    const actionData = JSON.parse(message.content || "{}");
    return (
      <CallToActionMessage
        content={actionData}
        conversationId={message.conversationId}
        messageId={message.id}
      />
    );
  } else if (message.messageType === "daily_task") {
    const taskData = JSON.parse(message.content || "{}");
    return (
      <DailyPlanMessage
        day={taskData.day}
        week={taskData.week}
        active={taskData.active}
        tasks={taskData.tasks}
        isFirstDay={
          taskData.tasks.length > 0 &&
          taskData.tasks[0] ===
            "<strong>Take a deep breath and slowly exhale.</strong> A long journey begins with a single step."
        }
      />
    );
  } else {
    return message.formattedContent
      ? message.formattedContent
      : message.content;
  }
}

function isSameDate(d1: Date, d2: Date) {
  return (
    d1.getDate() == d2.getDate() &&
    d1.getMonth() == d2.getMonth() &&
    d1.getFullYear() + d2.getFullYear()
  );
}

function MessageBubble({
  message,
  altColor,
  children,
}: {
  message: Message;
  altColor: boolean;
  children: React.ReactNode;
}) {
  // const coachMessageColor = altColor ? "bg-gradient-to-r from-[#00494D] via-[#006666] to-[#008080]" : "bg-coach";
  const coachMessageColor = altColor ? "bg-coach-alt" : "bg-coach";
  // const coachMessageColor = altColor ? "bg-gradient-to-r from-[#064E3B] via-[#086F54] to-[#0B8D6B]" : "bg-coach";
  const meMessageColor = altColor ? "bg-button-blue" : "bg-button-blue";

  if (message.submitted) {
    return (
      <div className="relative bg-gray-50 text-gray-800 border border-gray-200 rounded-lg p-2 my-2 text-center italic text-sm font-medium">
        {children}
      </div>
    );
  } else if (message.messengerRole !== "coach") {
    return (
      <div
        className={`relative rounded-2xl px-3 dialog-message max-w-[85%] py-2 ${meMessageColor} ms-auto me-2 text-white`}
      >
        {children}
      </div>
    );
  } else if (message.messageType == "form") {
    return (
      <div className="relative rounded-2xl px-3 dialog-message mt-4 py-4 bg-gray-50 border border-gray-200 text-gray-800">
        {children}
      </div>
    );
  } else if (message.messageType == "call_to_action") {
    return (
      <div className="relative rounded-2xl px-3 dialog-message py-4">
        {children}
      </div>
    );
  } else if (message.messageType == "plan") {
    return (
      <div className="relative rounded-2xl px-3 dialog-message py-4 bg-gray-50 border border-gray-200 text-gray-800">
        {children}
      </div>
    );
  } else if (message.messageType == "daily_task") {
    return (
      <div className="relative rounded-2xl px-3 py-2 dialog-message w-full bg-white ms-auto me-2 mb-2 text-black border border-gray-600">
        {children}
      </div>
    );
  } else {
    return (
      <div
        className={`relative rounded-2xl px-3 dialog-message max-w-[85%] ms-6 me-auto mt-4 py-4 ${coachMessageColor} text-white`}
      >
        {children}
      </div>
    );
  }
}

function CoachIcon() {
  return (
    <div className="flex flex-row content-center absolute top-[-35px] left-[-40px] text-[#7D7D7D] px-1">
      <img
        className="me-1"
        src="/static/coach-chat.png"
        alt="Coach logo"
        width="42"
        height="42"
        loading="lazy"
      />
      <span className="text-xs flex flex-col justify-center font-semibold">
        Coach
      </span>
    </div>
  );
}

function MessageEntry({
  i,
  message,
  previousMessage,
  nextMessage,
  coachIsWriting,
  onSuggestionChipClick,
  user,
}: {
  i: number;
  message: Message;
  previousMessage: undefined | Message;
  nextMessage: undefined | Message;
  coachIsWriting: boolean;
  onSuggestionChipClick: (chip: string) => void;
  user: User;
}) {
  const today = new Date();
  // If the message is on a new date compared to the previous one.
  var newDate: Date | undefined = undefined;

  if (message.postedAt && previousMessage && previousMessage.postedAt) {
    if (!isSameDate(message.postedAt, previousMessage.postedAt)) {
      newDate = message.postedAt;
    }
  }

  const altColor = user.experiments.includes("alt-color-061024");

  return (
    <Fragment key={message.id}>
      <div id={"dialog-" + i} className="w-1 h-1 scroll-mt-20"></div>
      {newDate && (
        <div className="bg-gray-50 text-gray-800 border border-gray-200 rounded-lg px-4 py-1 mx-auto my-2 text-center italic text-sm font-medium shadow-sm">
          {isSameDate(newDate, today) ? "Today" : newDate.toLocaleDateString()}
        </div>
      )}
      {message.submitted && (
        <div className="bg-gray-50 text-gray-800 border border-gray-200 rounded-lg p-2 my-2 text-center italic text-sm font-medium">
          Form submitted
        </div>
      )}
      {!message.submitted && (
        <MessageBubble message={message} altColor={altColor}>
          {message.messengerRole === "coach" &&
            message.messageType === "text" && <CoachIcon />}
          <div
            className={
              message.messageType == "daily_task"
                ? "px-1"
                : "overflow-hidden px-1"
            }
          >
            {formatMessage({
              message: message,
              user: user,
              blur:
                nextMessage !== undefined &&
                nextMessage.messageType == "call_to_action" &&
                JSON.parse(nextMessage.content || "{}").action == "subscribe",
            })}
          </div>
        </MessageBubble>
      )}
      {!nextMessage &&
        !coachIsWriting &&
        message.messengerRole === "coach" &&
        message.suggestionChips && (
          <div className="flex flex-row justify-end gap-2 mt-2 px-10">
            {message.suggestionChips
              .filter((chip: string) => chip.length != 0)
              .map((chip, i) => (
                <button
                  type="button"
                  key={i}
                  className="bg-button-blue-light text-button-blue border border-button-blue rounded-full px-4 py-1 text-button-blue min-h-[44px] min-w-[70px]"
                  onClick={() => onSuggestionChipClick(chip)}
                >
                  {chip}
                </button>
              ))}
          </div>
        )}
    </Fragment>
  );
}

function ConversationBlock({
  dialog,
  coachIsWriting,
  onSuggestionChipClick,
  user,
}: {
  dialog: Message[];
  coachIsWriting: boolean;
  onSuggestionChipClick: (chip: string) => void;
  user: User;
}) {
  const altColor = user.experiments.includes("alt-color-061024");

  function shouldDisplayMessage(
    message: Message,
    i: number,
    dialog: Message[],
  ): boolean {
    if (message.messageType === "form") {
      return i == dialog.length - 1 || message.submitted;
    }

    if (message.messageType === "call_to_action") {
      const contentParsed = JSON.parse(message.content || "{}");
      const isLastMessage = i === dialog.length - 1;
      const isSubscribeAction = contentParsed.action === "subscribe";
      return isLastMessage || isSubscribeAction;
    }

    return true;
  }

  return (
    <div id="dialog" className="flex flex-col gap-2">
      {dialog
        .filter((message, i) => shouldDisplayMessage(message, i, dialog))
        .map((message, i, messages) => (
          <MessageEntry
            key={message.id}
            i={i}
            message={message}
            previousMessage={i > 0 ? messages[i - 1] : undefined}
            nextMessage={i < messages.length - 1 ? messages[i + 1] : undefined}
            coachIsWriting={coachIsWriting}
            onSuggestionChipClick={onSuggestionChipClick}
            user={user}
          />
        ))}
      {coachIsWriting && (
        <div className="relative mt-6 max-w-[85%] ms-6 me-auto">
          <CoachIcon />
          <IsTyping altColor={altColor} />
        </div>
      )}
    </div>
  );
}

export default ConversationBlock;
