import React, { Fragment, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import parse from "html-react-parser";

import { config } from "../../Constants";
import { User } from "../../types";
import {
  addNewMessagesToTheQueryData,
  markLastMessageFormAsSubmitted,
} from "./message-page";
import { PrimaryButton, YesNoRadioButtons } from "../../components/Buttons";
import {
  PhoneInputField,
  SelectNumberField,
  SelectWeightKgField,
  SelectWeightLbsField,
  SelectTextField,
  SelectFeetHeightField,
} from "../../components/Form";
import { kgToLbs, lbsToKg, cmToFt, ftToCm, isEmailValid } from "../../utils";

import { useSwipeable } from "react-swipeable";

type WeightUnit = "kg" | "lbs";
type HeightUnit = "cm" | "ft";

function formContentToState(formContent: any) {
  var obj: any = {};
  if (formContent.fields) {
    for (const field of formContent.fields) {
      obj[field.id] = field.default;
    }
  }
  if (formContent.pages) {
    for (const page of formContent.pages) {
      for (const field of page.fields) {
        obj[field.id] = field.default;
      }
    }
  }

  return obj;
}

function computeWeightKeys(formContent: any): string[] {
  var res: string[] = [];
  if (formContent.fields) {
    for (const field of formContent.fields) {
      if (field.unit == "kg") res.push(field.id);
    }
  }
  if (formContent.pages) {
    for (const page of formContent.pages) {
      for (const field of page.fields) {
        if (field.unit == "kg") res.push(field.id);
      }
    }
  }

  return res;
}
function computeHeightKeys(formContent: any): string[] {
  var res: string[] = [];
  if (formContent.fields) {
    for (const field of formContent.fields) {
      if (field.unit == "cm") res.push(field.id);
    }
  }
  if (formContent.pages) {
    for (const page of formContent.pages) {
      for (const field of page.fields) {
        if (field.unit == "cm") res.push(field.id);
      }
    }
  }

  return res;
}

function FormMessage({
  user,
  formContent,
  conversationId,
  messageId,
}: {
  user: User;
  formContent: any;
  conversationId: number;
  messageId: number;
}) {
  const queryClient = useQueryClient();
  const [sendingForm, setSendingForm] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // All forms supports weight unit, wether or not they are actually needed in the form.
  const [weightUnit, setWeightUnit] = useState<WeightUnit>(
    user.currentWeightLbs ? "lbs" : "kg",
  );
  const [heightUnit, setHeightUnit] = useState<HeightUnit>("cm");

  const [formState, setFormState] = useState<any>(
    formContentToState(formContent),
  );

  const [submittable, setSubmittable] = useState<boolean>(true);

  const weightKeys = computeWeightKeys(formContent);
  const heightKeys = computeHeightKeys(formContent);

  const handleSwitchLbs = () => {
    setFormState((formState: any) => {
      let n = { ...formState };
      for (const [key, value] of Object.entries(formState)) {
        if (weightKeys.includes(key)) {
          n[key] = kgToLbs(value as number);
        }
      }
      return n;
    });
    setWeightUnit("lbs");
  };
  const handleSwitchKg = () => {
    setFormState((formState: any) => {
      let n = { ...formState };
      for (const [key, value] of Object.entries(formState)) {
        if (weightKeys.includes(key)) {
          n[key] = lbsToKg(value as number);
        }
      }
      return n;
    });
    setWeightUnit("kg");
  };
  const handleSwitchFt = () => {
    setFormState((formState: any) => {
      let n = { ...formState };
      for (const [key, value] of Object.entries(formState)) {
        if (heightKeys.includes(key)) {
          n[key] = cmToFt(value as number);
        }
      }
      return n;
    });
    setHeightUnit("ft");
  };
  const handleSwitchCm = () => {
    setFormState((formState: any) => {
      let n = { ...formState };
      for (const [key, value] of Object.entries(formState)) {
        if (heightKeys.includes(key)) {
          n[key] = ftToCm(value as string);
        }
      }
      return n;
    });
    setHeightUnit("cm");
  };

  const swipeHandlers = useSwipeable({
    onSwiped: (eventData: any) => {
      if (!formContent.pages || formContent.pages.length == 1) return;

      // We don't want this swipe to trigger the higher-level page swipe.
      eventData.event.stopPropagation();

      if (currentPage < formContent.pages.length && eventData.dir == "Left") {
        setCurrentPage(currentPage + 1);
      } else if (currentPage > 1 && eventData.dir == "Right") {
        setCurrentPage(currentPage - 1);
      }
    },
  });

  function handleNext() {
    if (currentPage >= formContent.pages.length) return;
    setCurrentPage(currentPage + 1);
  }

  const submitFormMutation = useMutation({
    mutationFn: (jsonData: any) =>
      fetch(`${config.url.API_URL}/form`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
        body: jsonData,
      }),
    onError: (error) => {
      console.error("Error submitting form", error);
      setSendingForm(false);
      queryClient.invalidateQueries({ queryKey: ["messages"] });
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    onSuccess: (res) => {
      res.json().then((newMessages) => {
        setSendingForm(false);
        markLastMessageFormAsSubmitted(queryClient);
        addNewMessagesToTheQueryData(queryClient, newMessages, true);
        queryClient.invalidateQueries({ queryKey: ["messages"] });
        queryClient.invalidateQueries({ queryKey: ["me"] });
      });
    },
  });

  function submitForm() {
    setSendingForm(true);

    var jsonObject: any = {
      id: formContent.id,
      cv_id: conversationId,
      me_id: messageId,
      weightUnit: weightUnit,
      heightUnit: heightUnit,
    };
    for (const [key, value] of Object.entries(formState)) {
      jsonObject[`${formContent.id}|${key}`] = value === undefined ? "" : value;
    }
    var jsonData = JSON.stringify(jsonObject);
    submitFormMutation.mutate(jsonData);
  }

  return (
    <div>
      <div className="font-extrabold mb-4 text-center">{formContent.label}</div>
      <form {...swipeHandlers} id={formContent.id}>
        {formContent.pages && (
          <>
            {formContent.pages.map((page: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className={currentPage == idx + 1 ? "" : "hidden"}
                >
                  <FormPage
                    fields={page.fields}
                    formContent={formContent}
                    formState={formState}
                    setFormState={setFormState}
                    setFormSubmittable={setSubmittable}
                    weightUnit={weightUnit}
                    heightUnit={heightUnit}
                    handleSwitchLbs={handleSwitchLbs}
                    handleSwitchKg={handleSwitchKg}
                    handleSwitchCm={handleSwitchCm}
                    handleSwitchFt={handleSwitchFt}
                  />
                </div>
              );
            })}
            <div className="flex flex-row justify-center gap-1 mt-12">
              {formContent.pages.map((page: any, idx: number) => (
                <button
                  type="button"
                  key={idx}
                  className={
                    "w-3 h-3 rounded-full " +
                    (currentPage == idx + 1 ? "bg-button-blue" : "bg-gray-300")
                  }
                  onClick={() => setCurrentPage(idx + 1)}
                ></button>
              ))}
            </div>
          </>
        )}
        {!formContent.pages && formContent.fields && (
          <FormPage
            fields={formContent.fields}
            formContent={formContent}
            formState={formState}
            setFormState={setFormState}
            setFormSubmittable={setSubmittable}
            weightUnit={weightUnit}
            heightUnit={heightUnit}
            handleSwitchLbs={handleSwitchLbs}
            handleSwitchKg={handleSwitchKg}
            handleSwitchCm={handleSwitchCm}
            handleSwitchFt={handleSwitchFt}
          />
        )}

        <div className="mt-4">
          {!formContent.pages || formContent.pages.length == currentPage ? (
            <PrimaryButton
              onClick={submitForm}
              label={formContent.cta}
              submitting={sendingForm}
              enabled={submittable}
            />
          ) : (
            <PrimaryButton
              onClick={handleNext}
              label={"Next"}
              submitting={false}
              enabled={submittable}
            />
          )}
        </div>
      </form>
    </div>
  );
}

function InputField({
  field,
  formState,
  setFormState,
}: {
  field: any;
  formState: any;
  setFormState: (x: any) => void;
}) {
  const [value, setValue] = useState(formState[field.id]);
  const [isEnabled, setIsEnabled] = useState(false);

  const updateFormState = (isEnabled: boolean, value: string) =>
    setFormState((formState: any) => {
      let n = { ...formState };
      n[field.id] = !field.optional || isEnabled ? value : "";
      return n;
    });

  const handleChange = (ev: any) => {
    setValue(ev.target.value);
    updateFormState(isEnabled, ev.target.value);
  };

  const handelIsEnabledChange = (newState: boolean) => {
    setIsEnabled(newState);
    updateFormState(newState, value);
  };

  return (
    <>
      <label htmlFor={field.id}>{field.label}</label>
      {field.optional && (
        <YesNoRadioButtons
          initialState={isEnabled}
          onStateChanged={handelIsEnabledChange}
        />
      )}
      {(!field.optional || isEnabled) && (
        <textarea
          id={field.id}
          name={field.id}
          className="resize-none p-2 rounded-lg border border-gray-200 bg-white"
          onChange={handleChange}
          value={value}
          placeholder={field.placeholder}
          rows={2}
        />
      )}
    </>
  );
}

function TextareaField({
  field,
  formState,
  setFormState,
}: {
  field: any;
  formState: any;
  setFormState: (x: any) => void;
}) {
  const handleChange = (ev: any) => {
    setFormState((formState: any) => {
      let n = { ...formState };
      n[field.id] = ev.target.value;
      return n;
    });
  };
  return (
    <>
      <label htmlFor={field.id}>{field.label}</label>
      <textarea
        id={field.id}
        name={field.id}
        className="resize-none p-2 rounded-lg border border-gray-200 bg-white"
        placeholder={field.placeholder}
        onChange={handleChange}
        value={formState[field.id]}
        rows={6}
      ></textarea>
    </>
  );
}

function LabelField({
  field,
  formState,
  setFormState,
}: {
  field: any;
  formState: any;
  setFormState: (x: any) => void;
}) {
  return <label className="mb-2">{field.label}</label>;
}

function CheckboxField({
  field,
  formState,
  setFormState,
}: {
  field: any;
  formState: any;
  setFormState: (x: any) => void;
}) {
  const handleChange = () => {
    setFormState((formState: any) => {
      let n = { ...formState };
      n[field.id] = formState[field.id] == "checked" ? "unchecked" : "checked";
      return n;
    });
  };

  return (
    <div className="flex flex-row gap-4 justify-start items-center">
      <input
        type="checkbox"
        id={field.id}
        name={field.name}
        onChange={handleChange}
        checked={formState[field.id] == "checked"}
        className="basis-4 grow-0 shrink-0 w-4 h-4 rounded-0 border-black border-2"
      />
      <label className="grow-1" htmlFor={field.id}>
        {parse(field.label)}
      </label>
    </div>
  );
}

function SelectField({
  field,
  formState,
  setFormState,
  weightUnit,
  heightUnit,
  handleSwitchLbs,
  handleSwitchKg,
  handleSwitchCm,
  handleSwitchFt,
}: {
  field: any;
  formState: any;
  setFormState: (x: any) => void;
  weightUnit: WeightUnit;
  heightUnit: HeightUnit;
  handleSwitchLbs: () => void;
  handleSwitchKg: () => void;
  handleSwitchCm: () => void;
  handleSwitchFt: () => void;
}) {
  const handleChangeText = (v: string) => {
    setFormState((formState: any) => {
      let n = { ...formState };
      n[field.id] = v;
      return n;
    });
  };
  const handleChangeNumber = (v: number) => {
    setFormState((formState: any) => {
      let n = { ...formState };
      n[field.id] = v;
      return n;
    });
  };

  if (field.value_type === "number") {
    if (field.unit) {
      let selectComponent: any = null;
      if (field.unit == "cm" && heightUnit == "ft") {
        selectComponent = (
          <SelectFeetHeightField
            id={field.id}
            label={field.label}
            value={formState[field.id]}
            setValue={(v) => {
              handleChangeText(v);
            }}
          />
        );
      } else {
        // Note that the backend always use kg or cm for field description, the pounds/feet is only a front-end preference.
        let valueToUnit: (n: number) => number = (n: number) =>
          field.unit === "kg" && weightUnit === "kg"
            ? n
            : field.unit === "kg" && weightUnit === "lbs"
              ? kgToLbs(n)
              : field.unit === "cm" && heightUnit === "cm"
                ? n
                : n;

        selectComponent = (
          <SelectNumberField
            id={field.id}
            label={field.label}
            value={formState[field.id]}
            setValue={(v: number) => handleChangeNumber(v)}
            min={valueToUnit(field.min)}
            max={valueToUnit(field.max)}
            unit={field.unit == "kg" ? weightUnit : heightUnit}
          />
        );
      }
      return (
        <>
          {selectComponent}
          {field.unit_toggle && field.unit == "kg" && (
            <>
              {weightUnit == "kg" ? (
                <button
                  type="button"
                  className="text-blue-link text-sm"
                  onClick={() => handleSwitchLbs()}
                >
                  Switch to pounds
                </button>
              ) : (
                <button
                  type="button"
                  className="text-blue-link text-sm"
                  onClick={() => handleSwitchKg()}
                >
                  Switch to kilos
                </button>
              )}{" "}
            </>
          )}
          {field.unit_toggle && field.unit == "cm" && (
            <>
              {heightUnit == "cm" ? (
                <button
                  type="button"
                  className="text-blue-link text-sm"
                  onClick={() => handleSwitchFt()}
                >
                  Switch to feet and inches
                </button>
              ) : (
                <button
                  type="button"
                  className="text-blue-link text-sm"
                  onClick={() => handleSwitchCm()}
                >
                  Switch to centimeters
                </button>
              )}
            </>
          )}
        </>
      );
    } else {
      return (
        <SelectNumberField
          id={field.id}
          label={field.label}
          value={formState[field.id]}
          setValue={handleChangeNumber}
          min={field.min}
          max={field.max}
        />
      );
    }
  } else {
    var options: any = [];

    field.values.forEach((value: any) =>
      options.push({ label: value.label, value: value.value }),
    );
    return (
      <SelectTextField
        id={field.id}
        label={field.label}
        value={formState[field.id]}
        setValue={handleChangeText}
        options={options}
      />
    );
  }
}

function SelectWeightField({
  field,
  formState,
  setFormState,
  weightUnit,
  handleSwitchLbs,
  handleSwitchKg,
}: {
  field: any;
  formState: any;
  setFormState: (x: any) => void;
  weightUnit: WeightUnit;
  handleSwitchLbs: () => void;
  handleSwitchKg: () => void;
}) {
  const handleChange = (v: number) => {
    setFormState((formState: any) => {
      let n = { ...formState };
      n[field.id] = v;
      return n;
    });
  };

  let selectComponent: any = null;
  if (weightUnit == "kg") {
    selectComponent = (
      <SelectWeightKgField
        id={field.id}
        label={field.label}
        value={formState[field.id]}
        setValue={(v) => handleChange(v)}
      />
    );
  } else if (weightUnit == "lbs") {
    selectComponent = (
      <SelectWeightLbsField
        id={field.id}
        label={field.label}
        value={formState[field.id]}
        setValue={(v) => handleChange(v)}
      />
    );
  }
  return (
    <>
      {selectComponent}
      {field.unit_toggle && field.unit == "kg" && (
        <>
          {weightUnit == "kg" ? (
            <button
              type="button"
              className="text-blue-link text-sm"
              onClick={() => handleSwitchLbs()}
            >
              Switch to pounds
            </button>
          ) : (
            <button
              type="button"
              className="text-blue-link text-sm"
              onClick={() => handleSwitchKg()}
            >
              Switch to kilos
            </button>
          )}{" "}
        </>
      )}
    </>
  );
}

function SelectBoxesField({
  field,
  formState,
  setFormState,
}: {
  field: any;
  formState: any;
  setFormState: (x: any) => void;
}) {
  const options: any = [];
  field.values.forEach((value: any) =>
    options.push({ label: value.label, value: value.value }),
  );

  const otherValuePrefix = "other:";

  const editableOtherSelected =
    field.multiple &&
    field.editable_other &&
    formState[field.id]?.find((x: string) => x.startsWith(otherValuePrefix));

  const handleSelectionChange = (v: string) => {
    console.log(v);
    setFormState((formState: any) => {
      console.log(formState);
      let n = { ...formState };
      if (field.multiple) {
        let n2 = n[field.id];
        if (field.editable_other && v == "other") {
          if (editableOtherSelected) {
            n[field.id] = n2.filter(
              (x: string) => !x.startsWith(otherValuePrefix),
            );
          } else {
            n[field.id] = [...n2, otherValuePrefix];
          }
        } else {
          if (n2.includes(v)) {
            n[field.id] = n2.filter((x: string) => x != v);
          } else {
            n[field.id] = [...n2, v];
          }
        }
        console.log(n);
      } else {
        n[field.id] = v;
      }
      return n;
    });
  };

  const extractOtherValue = () => {
    let otherValue = formState[field.id].find((x: string) =>
      x.startsWith(otherValuePrefix),
    );
    if (otherValue) {
      return otherValue.slice(otherValuePrefix.length);
    }
    return undefined;
  };

  const handleOtherInputChange = (ev: any) => {
    const otherValue = extractOtherValue();
    if (otherValue === undefined) {
      return;
    }
    setFormState((formState: any) => {
      let n = { ...formState };
      n[field.id] = n[field.id].filter(
        (x: string) => !x.startsWith(otherValuePrefix),
      );
      n[field.id].push(otherValuePrefix + ev.target.value);
      return n;
    });
  };

  return (
    <>
      <label htmlFor={field.id}>{field.label}</label>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row flex-wrap gap-2">
          {options.map((option: any) => (
            <button
              type="button"
              key={option.value}
              onClick={() => handleSelectionChange(option.value)}
              className={
                "min-w-28 max-w-44 basis-2/5 py-3 rounded-lg border border-button-blue text-sm font-medium text-center " +
                ((field.multiple &&
                  formState[field.id].includes(option.value)) ||
                option.value === formState[field.id] ||
                (editableOtherSelected && option.value === "other")
                  ? "bg-button-blue text-white"
                  : "bg-button-blue-light text-button-blue")
              }
            >
              {option.label}
            </button>
          ))}
        </div>
        {field.editable_other && editableOtherSelected && (
          <textarea
            className="resize-none p-2 rounded-lg border border-gray-200 bg-white"
            placeholder={field.placeholder}
            rows={1}
            value={extractOtherValue()}
            onChange={handleOtherInputChange}
          />
        )}
      </div>
    </>
  );
}

function wrapLabel(label: string, recommended: boolean) {
  if (recommended) {
    return (
      <span>
        <strong>(Optional but recommended)</strong> {label}
      </span>
    );
  } else {
    return <span>{label}</span>;
  }
}

function PhoneField({
  field,
  formState,
  setFormState,
}: {
  field: any;
  formState: any;
  setFormState: (x: any) => void;
}) {
  const handleChange = (v: string) => {
    setFormState((formState: any) => {
      let n = { ...formState };
      n[field.id] = v;
      return n;
    });
  };

  return (
    <PhoneInputField
      id={field.id}
      label={wrapLabel(field.label, field.recommended)}
      value={formState[field.id]}
      setValue={handleChange}
    />
  );
}

function BuddyField({
  field,
  formState,
  setFormState,
  setFormSubmittable,
}: {
  field: any;
  formState: any;
  setFormState: (x: any) => void;
  setFormSubmittable: (x: boolean) => void;
}) {
  const [name, setName] = useState(formState[field.id]?.name || "");
  const [email, setEmail] = useState(formState[field.id]?.email || "");
  const [validationError, setValidationError] = useState<string | null>(null);

  const getNameEmailPairValidationError = (
    name: string,
    email: string,
  ): string | null => {
    const nameTrimmed = name.trim();
    const emailTrimmed = email.trim();
    if (nameTrimmed.length == 0 && emailTrimmed.length == 0) {
      return null;
    }
    if (nameTrimmed.length == 0) {
      return "Name field is empty";
    }
    if (emailTrimmed.length == 0) {
      return "Email field is empty";
    }
    if (!isEmailValid(emailTrimmed)) {
      return "Email is invalid";
    }
    return null;
  };

  const handleNameChange = (ev: any) => {
    setName(ev.target.value);
    setFormState((formState: any) => {
      let n = { ...formState };
      n[field.id] = { name: ev.target.value, email: email };
      return n;
    });
    const validationError = getNameEmailPairValidationError(
      ev.target.value,
      email,
    );
    setFormSubmittable(!validationError);
    setValidationError(validationError);
  };

  const handleEmailChange = (ev: any) => {
    setEmail(ev.target.value);
    setFormState((formState: any) => {
      let n = { ...formState };
      n[field.id] = { name: name, email: ev.target.value };
      return n;
    });
    const validationError = getNameEmailPairValidationError(
      name,
      ev.target.value,
    );
    setFormSubmittable(!validationError);
    setValidationError(validationError);
  };

  return (
    <div className="flex flex-col text-gray-800">
      <div>
        Having someone to celebrate wins, commiserate setbacks, and hold you
        accountable can double your motivation and make achieving your goals
        even more rewarding.
      </div>
      <div className="mt-6">
        <strong>What happens when you add a buddy?</strong>
      </div>
      <div>
        Your accountability buddy will receive an email at the end of each week
        with a report of your progress, including what tasks you completed and
        which ones you missed.
      </div>
      <div className="mt-6">
        Buddies are not required to sign-up, and won't even be asked to do so.
      </div>
      <div className="mt-6">Add your first accountability buddy:</div>
      <input
        id={`${field.id}_name`}
        name={`${field.id}_name`}
        className="resize-none p-2 rounded-lg border border-gray-200 bg-white mt-2"
        onChange={handleNameChange}
        value={name}
        placeholder="Name of buddy"
      />
      <input
        type="email"
        id={`${field.id}_email`}
        name={`${field.id}_email`}
        className="resize-none p-2 rounded-lg border border-gray-200 bg-white mt-2"
        onChange={handleEmailChange}
        value={email}
        placeholder="Email of buddy"
      />
      <div className="text-sm mt-6 mb-8">
        You can add more buddies at anytime in your profile.
      </div>
      {validationError && <div className="text-red-500">{validationError}</div>}
    </div>
  );
}

function FormPage({
  fields,
  formContent,
  formState,
  setFormState,
  setFormSubmittable,
  weightUnit,
  heightUnit,
  handleSwitchLbs,
  handleSwitchKg,
  handleSwitchCm,
  handleSwitchFt,
}: {
  fields: any;
  formContent: any;
  formState: any;
  setFormState: (x: any) => void;
  setFormSubmittable: (x: boolean) => void;
  weightUnit: WeightUnit;
  heightUnit: HeightUnit;
  handleSwitchLbs: () => void;
  handleSwitchKg: () => void;
  handleSwitchCm: () => void;
  handleSwitchFt: () => void;
}) {
  return (
    <>
      {fields.map((field: any, i: number) => (
        <Fragment key={i}>
          {field.type === "checkbox" && (
            <div key={field.id} className="mb-2">
              <CheckboxField
                field={field}
                formState={formState}
                setFormState={setFormState}
              />
            </div>
          )}
          {field.type !== "checkbox" && (
            <div key={field.id} className="flex flex-col gap-2 mt-5">
              {field.type === "label" && (
                <LabelField
                  field={field}
                  formState={formState}
                  setFormState={setFormState}
                />
              )}
              {field.type === "select" && (
                <SelectField
                  field={field}
                  formState={formState}
                  setFormState={setFormState}
                  weightUnit={weightUnit}
                  heightUnit={heightUnit}
                  handleSwitchLbs={handleSwitchLbs}
                  handleSwitchKg={handleSwitchKg}
                  handleSwitchCm={handleSwitchCm}
                  handleSwitchFt={handleSwitchFt}
                />
              )}
              {field.type === "select-weight" && (
                <SelectWeightField
                  field={field}
                  formState={formState}
                  setFormState={setFormState}
                  handleSwitchLbs={handleSwitchLbs}
                  handleSwitchKg={handleSwitchKg}
                  weightUnit={weightUnit}
                />
              )}
              {field.type === "select-boxes" && (
                <SelectBoxesField
                  field={field}
                  formState={formState}
                  setFormState={setFormState}
                />
              )}
              {field.type === "free" && (
                <InputField
                  field={field}
                  formState={formState}
                  setFormState={setFormState}
                />
              )}
              {field.type === "free-large" && (
                <TextareaField
                  field={field}
                  formState={formState}
                  setFormState={setFormState}
                />
              )}
              {field.type === "phone" && (
                <PhoneField
                  field={field}
                  formState={formState}
                  setFormState={setFormState}
                />
              )}
              {field.type === "buddy" && (
                <BuddyField
                  field={field}
                  formState={formState}
                  setFormState={setFormState}
                  setFormSubmittable={setFormSubmittable}
                />
              )}
            </div>
          )}
        </Fragment>
      ))}
    </>
  );
}

export default FormMessage;
